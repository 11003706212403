.redeempoints-section {
    background: rgba(18, 17, 39, 0.04);
    padding: 80px 0 80px 0;
}

.redeempoints-section .section-detail {

    margin-top: 20px;
}

.inner-redeempoint {
    width: 80vw;
    margin: 0 auto;
}

.referral-main {
    margin-top: 30px;
}

.refrral-block {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px;
    margin: 20px 0;
}

.refrral-heading {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.left-heading h3 .bold {
    font-weight: 700;

}

.left-heading h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 400 !important;

    color: rgba(18, 17, 39, 0.72);
}

.price-btn {
    padding: 10px 30px;
    border: none;
    height: 37px;
    color: white;
    background: #23A5C7;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 10px;

}

.price-btn.gray {
    background: #D1D1DB;
    color: #16212C;
}

.price-btn:hover{
    background: #23A5C7;
    color: #fff;
}

.price-btn.gray.redeem-btn {
    font-weight: 700;
    margin-left: 30px;
    text-align: center;
    background: #23A5C7;

}

.instr {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;

    margin-top: 30px;
    color: rgba(18, 17, 39, 0.72);
}

.ref-btn {
    padding: 0px 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 44px;
    border: none;
    outline: none;
    text-align: center;
    background: #41BCD8;
    border-radius: 8px;
    margin: 0 auto;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
}

.ref-btn:hover {
    background: #8feaff;
}

.card-btn {
    text-align: center;
    margin-top: 25px;
}

.ref-btn img {
    margin-left: 10px;
}

.right-heading input {
    width: 180px;
    height: 37px;

    border: 1px solid #9D9CAF;
    border-radius: 8px;
    text-align: right;
    padding: 0 15px;
}

.confirm-msg {
    margin: 20px 0;
}

.confirm-msg h3 {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    font-size: 20px;

    text-align: center;
    text-transform: uppercase;

    color: #16212C;

}

.check-mark p {
    margin-left: 20px;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    /* Grayscale 900 Opacity/72% */

    color: rgba(18, 17, 39, 0.72);


}

.check-mark {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.check-mark input {
    color: #23A5C7;
    width: 29px;
    height: 30px;
}

.dc {
    padding: 18px 51px !important;

}

@media (max-width:1200px) {
    .inner-redeempoint {
        width: 90vw;
        margin: 0 auto;
    }

    .right-heading {
        display: flex;
    }
}

@media (max-width:900px) {
    .right-heading {
        flex-wrap: wrap;

    }

    .price-btn.gray.redeem-btn {
        margin: 10px;
        /* margin-top: 10px !important; */
    }

    .price-btn {
        margin-top: 10px;
        width: 118px;
        margin-bottom: 10px;
    }

    .right-heading {
        justify-content: right;
    }

    .instr {
        margin: 15px 0;
    }


    .inner-redeempoint {
        width: auto;
        padding: 0 40px;
    }

    .refrral-block {
        padding: 30px 20px;
    }

    .ref-btn {
        font-size: 16px;
        padding: 0 20px;
    }
}

@media (max-width:600px) {
    .refrral-block {
        padding: 20px 15px;
    }

    .inner-redeempoint {
        width: auto;
        padding: 0 20px;
    }

    .redeempoints-section {
        padding: 40px 0 40px 0;
    }

    .ref-btn {
        overflow: auto;
    }
}

@media (max-width:550px) {
    .refrral-heading {
        flex-direction: column;
    }

    .right-heading {
        justify-content: center;
        margin-top: 10px;
        align-items: center;
    }

    .ref-btn {
        width: 100%;
        padding: 0 10px;
    }

    .price-btn {
        font-size: 13px;
        width: 110px;
    }

    .ref-btn {
        font-size: 16px;
    }

    .right-heading input {
        width: 110px;
    }

    .confirm-msg h3 {
        font-size: 18px;
    }


    .confirm-msg {
        margin: 15px 0;

    }
}