.affiliate-area {
    margin-top: 80px !important;
    width: 80%;
    margin: 0 auto;
}

.why .section-slider {

    background: #FFFFFF;
    /* border: 1px solid rgba(0, 0, 0, 0.06); */
    border-radius: 8px;
    box-shadow: 5px 15px 15px rgba(0, 0, 0, 0.04);

}

.affiliate-img {
    margin-top: 30px !important;
    width: 120px;
    margin: 0 auto;
    height: 120px;
}

.affiliate-area .slick-dots li button, .affiliate-area .slick-dots li.slick-active button:before, .affiliate-area .slick-dots li button:before {
    color: rgb(0, 0, 0) !important;
    /* opacity: 1 !important; */
}

.left-piece {
    position: absolute;
    left: 0;
    text-align: left;
    bottom: 0;
}

.right-piece {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
}

.right-piece img,
.left-piece img {
    width: 90%;
}

@media (max-width:1000px) {

    .right-piece img,
    .left-piece img {
        width: 130px;

    }
}

@media (max-width:600px) {
    .section-heading {
        font-size: 28px;
    }

    .right-piece img, .left-piece img {
        width: 100px;
    }
}