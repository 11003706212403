.disc-box {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 30px 20px;
    width: 351px;
    margin: 0 auto;
    background: #41BCD8;
}

.disc-box .point-number {
    color: white;
}

.disc-box p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

}

.disc-para {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #16212C;


}

.border-box.disc-border {
    height: 110px;
}

.disc-bx .indiv-bx {
    width: 250px;
}

.indiv-disc h3 {
    font-weight: 700 !important;
    font-size: 16px !important;
    margin-top: 10px !important;
    text-align: center;
    text-transform: uppercase;
    color: rgba(18, 17, 39, 0.72);

}

.italic-point {
    margin-top: 50px;
}

.italic-point p {
    font-style: italic;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: capitalize;

    /* Grayscale / 900 */

    color: #121127;
}

.three-bx {
    margin-top: 20px !important;
}

.disc-left {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 50px;
}

.disc-right {
    position: absolute;
    top: 25%;
    right: 0;
    margin-top: 50px;
}

.bell{
    position: absolute;
    z-index: 2;
}

.redBell{
    position: absolute;
    z-index: 1;
    opacity: 0.5;
}

.bell:hover{
    opacity: 0;
}

@media (max-width:768px) {
    .disc-left, .disc-right {
        display: none;
    }

    .disc-para {
        font-size: 18px;
    }

    .italic-point p {
        font-size: 25px;
    }

    .rewards-section.pt-section.dics {
        padding: 100px 0 50px 0;
    }
}

@media (max-width:550px) {
    .disc-box {
        background: #FFFFFF;
        border-radius: 8px;
        padding: 16px 0px;
        width: 277px;
        margin: 0 auto;
        background: #41BCD8;
    }

    .disc-para {
        font-size: 16px;
        width: 277px;
        margin-left: auto;
        margin-right: auto;
    }

    .italic-point p {
        font-size: 22px;
    }

    .three-bx {
        margin-top: 0 !important;
    }
}