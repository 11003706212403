* {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
  /* list-style: none; */
}

ul li {
  /* list-style: none; */
}

button {
  cursor: pointer;
}